// import axios from "axios";
// import React, { useState } from "react";
// import { Link } from 'react-router-dom';
// import logo from "./assests/images/logo.svg";
// import img_1 from "./assests/images/img_1.png";
// import img_career from "./assests/images/img_career.svg";
// import angell from "./assests/images/angell.png";
// import facebook from "./assests/images/facebookk.png";
// import insta from "./assests/images/insta.png";
// import linkedin from "./assests/images/linkedin.png";
// import location from "./assests/images/location.png";
// import tweeter from "./assests/images/tweet.png";
// import call from "./assests/images/call.png";
// import mail from "./assests/images/emacon.png";

// function Career1() {
//     return (
//       <div>
//         <div className="career-container">
//           <header>
//             <div className="logo">
//               <img src={logo} alt="Blog Logo" />
//             </div>
//             <div className="img_1">
//               <img src={img_1} alt="....." />
//             </div>
  
//             <ul className="navbar">
//             <li><Link to="/">Home</Link></li>
//             <li className="active-linkk"><Link to="/career" >Career</Link></li>
//       <li><Link to="/about">About</Link></li>
//       <li><Link to="/contact">Contact</Link></li>
//             </ul>
//           </header>
  
//           <div className="one-container">
//             <h4 className="car">Join Us</h4>
//             <div className="care">
//               <h2>"Shape the Future of AI Fashion with Us"</h2>
//             </div>
//             <div className="carei">
//           Join our team and become part of a cultting-edge. AI-driven fashion revolution. As a member of our innovative crew. you'll have the opportunity to-
//             </div>
//           </div>
//           <div className="careerr-container">
//             <img src={img_career} alt="two" />
//           </div>
//         </div>
//       </div>
//     )
//   }

//   function Career2() {
//     const [email, setEmail] = useState("");
//     const [isValidEmail, setIsValidEmail] = useState(true);

//   const loadScript = (src) => {
//     return new Promise((resolve) => {
//       const script = document.createElement("script");
//       script.src = src;
//       script.onload = () => {
//         resolve(true);
//       };
//       script.onerror = () => {
//         resolve(false);
//       };
//       document.body.appendChild(script);
//     });
//   };

//   const validateEmail = (email) => {
//     const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
//     return regex.test(email);
//   };

//   const displayRazorpay = async () => {
//     if (!validateEmail(email)) {
//       setIsValidEmail(false);
//       return;
//     }

//     const res = await loadScript(
//       "https://checkout.razorpay.com/v1/checkout.js"
//     );
//     if (!res) {
//       alert("You are offline...... Failed to load razorpay SDK");
//       return;
//     }

//     const options = {
//       key: "rzp_test_BqwXmtR5v1PWNh",
//       currency: "INR",
//       amount: 3900,
//       name: "eveo",
//       description: "Thanks for joining",
//       handler: async function (response) {
//         const jsonData = {
//           Email: email,
//         };

//         try {
//           await axios.post("/", jsonData, {
//             headers: {
//               "Content-Type": "application/json",
//             },
//           });

//           alert("Payment Successfully");
//           const storedEmail = localStorage.getItem("userEmail");
//           localStorage.setItem("userEmail", email);
//           console.log("Stored Email:", storedEmail);
//         } catch (error) {
//           console.error("Error:", error);
//         }
//       },
//       prefill: {
//         name: "eveo",
//       },
//     };

//     const paymentObject = new window.Razorpay(options);
//     paymentObject.open();
//   };
//   return (
//     <div>
//       <div className="career2-container">
//         <h4 className="vacan">Available Job Vacancies at Eveo</h4>
// <div className="job1-container">
    
// </div>
// <h2 className="j-title">Job Title</h2>
//     <h2 className="experience">Experience</h2>
//     <h2 className="position">Open Position</h2>
//     <button className="apply">Apply</button>
//     <h4 className="senior">Senior UI/UX Designer</h4>
//     <h4 className="years">4-6 years</h4>
//     <h4 className="num-1">1</h4>

//     <div className="job1-container">
    
// </div>
// <h2 className="j-title">Job Title</h2>
//     <h2 className="experience">Experience</h2>
//     <h2 className="position">Open Position</h2>
//     <button className="apply">Apply</button>
//     <h4 className="senior">Senior UI/UX Designer</h4>
//     <h4 className="years">4-6 years</h4>
//     <h4 className="num-1">1</h4>

//     <div className="job1-container">
    
// </div>
// <h2 className="j-title">Job Title</h2>
//     <h2 className="experience">Experience</h2>
//     <h2 className="position">Open Position</h2>
//     <button className="apply">Apply</button>
//     <h4 className="senior">Senior UI/UX Designer</h4>
//     <h4 className="years">4-6 years</h4>
//     <h4 className="num-1">1</h4>

//     <div className="job1-container">
    
// </div>
// <h2 className="j-title">Job Title</h2>
//     <h2 className="experience">Experience</h2>
//     <h2 className="position">Open Position</h2>
//     <button className="apply">Apply</button>
//     <h4 className="senior">Senior UI/UX Designer</h4>
//     <h4 className="years">4-6 years</h4>
//     <h4 className="num-1">1</h4>

//     <div className="ready-container">
//           <p className="ready">
//             &quot; Ready to Get Started Join Eveo Today &quot;
//           </p>

// <form action="/" method="get">
//               <div className="input-group">
                
                
                
//                 <input
//                   className="ready-input"
//                   placeholder='     Enter Your email'
//                   type="email2"
//                   value={email}
//                   onChange={(e) => {
//                     setEmail(e.target.value);
//                     setIsValidEmail(true);
//                   }}
//                 />
                
//                 <button
//                   type="button"
//                   className="btn-class"
//                   onClick={() => displayRazorpay()}
//                 >
//                   Join Now
//                 </button>
//               </div>
//               {!isValidEmail && (
//                   <p className="invalid" style={{ color: "black" }}>Invalid email address</p>
//                 )}
             
//             </form>
//         </div>

//         <div className="back-container"></div>
//       </div>
      
//     </div>

//   )
// }

// function Footer() {
//     return (
//       <div>
//         <div className="footer-containerr">
//           <div className="footer-in-container">
//             <div className="logo-2">
//               <img src={logo} alt="Blog Logo" />
//             </div>
//             <p className="foot-content">
//               At Eveo,we are trying to align the fashion according to your
//               preference and under your budget by offering personalized
//               guidence.using AI-AR to transform personality management for events.
//             </p>
  
//             <div className="contactus">Contact Us</div>
//             <p className="footer-mail">
//               {" "}
//               <img src={mail} alt="mail" />
//               <p className="cmail">info@eveo.in</p>
//             </p>
//             <p className="footer-call">
//               {" "}
//               <img src={call} alt="call" />
//               <p className="mobile">+0261 3177919</p>
//             </p>
//             <div className="useful-links">Useful Links</div>
//             <p className="us-1">About us</p>
//             <p className="us-2">Services</p>
//             <p className="us-3">Career</p>
//             <p className="us-4">Terms & Conditions</p>
//             <div className="footer-con">Let's Stay Connected</div>
//             <p className="all-5">
//               {" "}
//               <a href="https://www.instagram.com/eveoai/">
//                 <img src={insta} alt="insta" />
//               </a>
//               <a href="https://www.facebook.com/eveoai">
//                 <img src={facebook} alt="facebook" />
//               </a>
//               <a href="/">
//                 <img src={tweeter} alt="tweeter" />
//               </a>
//               <a href="https://www.linkedin.com/company/eveoai/">
//                 <img src={linkedin} alt="linkedin" />
//               </a>
//               <a href="https://wellfound.com/company/eveo">
//                 <img src={angell} alt="angell" />
//               </a>
//             </p>
//           </div>
  
//           <p className="surat-location">
//             {" "}
//             <img src={location} alt="location" />
//           </p>
  
//           <p className="surat-add">&nbsp; Surat(HQ)</p>
//           <p className="loc">
//             G 103-104, Silver Business Point, VIP Circle, Uttran, Surat 394105
//           </p>
  
//           <p className="ahmbd-location">
//             {" "}
//             <img src={location} alt="location" />
//           </p>
//           <p className="ahmbd-add">&nbsp; Ahemdabad</p>
//           <p className="loc-1">
//             Block no.3,LD Engg. college, Navrangpura, Ahemdabad-380009
//           </p>
  
//           <p className="patna-location">
//             {" "}
//             <img src={location} alt="location" />
//           </p>
//           <p className="patna-add">&nbsp; Patna</p>
//           <p className="loc-2">
//             Room No. H,IC-IIT Patna Campus,Bihta,Patna-801103
//           </p>
  
//           <p className="jaypur-location">
//             {" "}
//             <img src={location} alt="location" />
//           </p>
//           <p className="jaypur-add">&nbsp; Jaipur</p>
//           <p className="loc-3">
//             Office no 15, MNIT Innovation and Incubation Center Jaipur- 302017
//           </p>
//         </div>
//       </div>
//     );
//   }

// function Career() {
//   return (
//     <div>
//       <Career1/>
//       <Career2/>
//       <Footer/>
//     </div>
//   )
// }

// export default Career




import axios from "axios";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from "./assests/images/logo.svg";
import img_1 from "./assests/images/img_1.png";
import img_career from "./assests/images/img_career.svg";
import angell from "./assests/images/angell.png";
import facebook from "./assests/images/facebookk.png";
import insta from "./assests/images/insta.png";
import linkedin from "./assests/images/linkedin.png";
import location from "./assests/images/location.png";
import tweeter from "./assests/images/tweet.png";
import call from "./assests/images/call.png";
import mail from "./assests/images/emacon.png";

function Career1() {
    return (
      <div>
        <div className="career-container">
          <header>
            <div className="logo">
              <img src={logo} alt="Blog Logo" />
            </div>
            <div className="img_1">
              <img src={img_1} alt="....." />
            </div>
  
            <ul className="navbar">
            <li><Link to="/">Home</Link></li>
            <li className="active-linkk"><Link to="/career" >Career</Link></li>
      <li><Link to="/about">About</Link></li>
      <li><Link to="/contact">Contact</Link></li>
            </ul>
          </header>
  
          <div className="one-container">
            <h4 className="car">Join Us</h4>
            <div className="care">
              <h2>"Shape the Future of AI Fashion with Us"</h2>
            </div>
            <div className="carei">
          Join our team and become part of a cultting-edge. AI-driven fashion revolution. As a member of our innovative crew. you'll have the opportunity to-
            </div>
          </div>
          <div className="careerr-container">
            <img src={img_career} alt="two" />
          </div>
        </div>
      </div>
    )
  }

  function Career2() {
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");


  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    // Validation
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    // Create a data object with form field values
    const formData = {
      firstName,
      lastName,
      phoneNumber,
      email
    };

    try {
      const response = await axios.post("/submitApplication", formData);
      console.log("Form submitted successfully", response.data);
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setEmail("");
      setIsValidEmail(true); 
    } catch (error) {
      console.error("Error submitting form", error);

    }
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const displayApplyForm = () => {
    // You can implement your form display logic here.
    // This could be a modal or another component.
    // For simplicity, I'll render a form directly in the component.
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsValidEmail(true);
            }}
            required
          />
          {!isValidEmail && (
            <p className="error">Please enter a valid email address</p>
          )}
        </div>
        <button type="submit">Submit</button>
      </form>
    );
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("You are offline...... Failed to load razorpay SDK");
      return;
    }

    const options = {
      key: "rzp_test_BqwXmtR5v1PWNh",
      currency: "INR",
      amount: 3900,
      name: "eveo",
      description: "Thanks for joining",
      handler: async function (response) {
        const jsonData = {
          Email: email,
        };

        try {
          await axios.post("/", jsonData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          alert("Payment Successfully");
          const storedEmail = localStorage.getItem("userEmail");
          localStorage.setItem("userEmail", email);
          console.log("Stored Email:", storedEmail);
        } catch (error) {
          console.error("Error:", error);
        }
      },
      prefill: {
        name: "eveo",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return (
    <div>
      <div className="career2-container">
        <h4 className="vacan">Available Job Vacancies at Eveo</h4>
<div className="job1-container">
    
</div>
<h2 className="j-title">Job Title</h2>
    <h2 className="experience">Experience</h2>
    <h2 className="position">Open Position</h2>
    <button className="apply" onClick={displayApplyForm}>Apply</button>
    <h4 className="senior">Senior UI/UX Designer</h4>
    <h4 className="years">4-6 years</h4>
    <h4 className="num-1">1</h4>

    <div className="job1-container">
    
</div>
<h2 className="j-title">Job Title</h2>
    <h2 className="experience">Experience</h2>
    <h2 className="position">Open Position</h2>
    <button className="apply">Apply</button>
    <h4 className="senior">Senior UI/UX Designer</h4>
    <h4 className="years">4-6 years</h4>
    <h4 className="num-1">1</h4>

    <div className="job1-container">
    
</div>
<h2 className="j-title">Job Title</h2>
    <h2 className="experience">Experience</h2>
    <h2 className="position">Open Position</h2>
    <button className="apply">Apply</button>
    <h4 className="senior">Senior UI/UX Designer</h4>
    <h4 className="years">4-6 years</h4>
    <h4 className="num-1">1</h4>

    <div className="job1-container">
    
</div>
<h2 className="j-title">Job Title</h2>
    <h2 className="experience">Experience</h2>
    <h2 className="position">Open Position</h2>
    <button className="apply" onClick={displayApplyForm}>Apply</button>
    <h4 className="senior">Senior UI/UX Designer</h4>
    <h4 className="years">4-6 years</h4>
    <h4 className="num-1">1</h4>

    <div className="ready-container">
          <p className="ready">
            &quot; Ready to Get Started Join Eveo Today &quot;
          </p>

<form action="/" method="get">
              <div className="input-group">
                
                
                
                <input
                  className="ready-input"
                  placeholder='     Enter Your email'
                  type="email2"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setIsValidEmail(true);
                  }}
                />
                
                <button
                  type="button"
                  className="btn-class"
                  onClick={() => displayRazorpay()}
                >
                  Join Now
                </button>
              </div>
              {!isValidEmail && (
                  <p className="invalid" style={{ color: "black" }}>Invalid email address</p>
                )}
             
            </form>
        </div>

        <div className="back-container"></div>
      </div>
      
    </div>

  )
}

function Footer() {
    return (
      <div>
        <div className="footer-containerr">
          <div className="footer-in-container">
            <div className="logo-2">
              <img src={logo} alt="Blog Logo" />
            </div>
            <p className="foot-content">
              At Eveo,we are trying to align the fashion according to your
              preference and under your budget by offering personalized
              guidence.using AI-AR to transform personality management for events.
            </p>
  
            <div className="contactus">Contact Us</div>
            <p className="footer-mail">
              {" "}
              <img src={mail} alt="mail" />
              <p className="cmail">info@eveo.in</p>
            </p>
            <p className="footer-call">
              {" "}
              <img src={call} alt="call" />
              <p className="mobile">+0261 3177919</p>
            </p>
            <div className="useful-links">Useful Links</div>
            <p className="us-1">About us</p>
            <p className="us-2">Services</p>
            <p className="us-3">Career</p>
            <p className="us-4">Terms & Conditions</p>
            <div className="footer-con">Let's Stay Connected</div>
            <p className="all-5">
              {" "}
              <a href="https://www.instagram.com/eveoai/">
                <img src={insta} alt="insta" />
              </a>
              <a href="https://www.facebook.com/eveoai">
                <img src={facebook} alt="facebook" />
              </a>
              <a href="/">
                <img src={tweeter} alt="tweeter" />
              </a>
              <a href="https://www.linkedin.com/company/eveoai/">
                <img src={linkedin} alt="linkedin" />
              </a>
              <a href="https://wellfound.com/company/eveo">
                <img src={angell} alt="angell" />
              </a>
            </p>
          </div>
  
          <p className="surat-location">
            {" "}
            <img src={location} alt="location" />
          </p>
  
          <p className="surat-add">&nbsp; Surat(HQ)</p>
          <p className="loc">
            G 103-104, Silver Business Point, VIP Circle, Uttran, Surat 394105
          </p>
  
          <p className="ahmbd-location">
            {" "}
            <img src={location} alt="location" />
          </p>
          <p className="ahmbd-add">&nbsp; Ahemdabad</p>
          <p className="loc-1">
            Block no.3,LD Engg. college, Navrangpura, Ahemdabad-380009
          </p>
  
          <p className="patna-location">
            {" "}
            <img src={location} alt="location" />
          </p>
          <p className="patna-add">&nbsp; Patna</p>
          <p className="loc-2">
            Room No. H,IC-IIT Patna Campus,Bihta,Patna-801103
          </p>
  
          <p className="jaypur-location">
            {" "}
            <img src={location} alt="location" />
          </p>
          <p className="jaypur-add">&nbsp; Jaipur</p>
          <p className="loc-3">
            Office no 15, MNIT Innovation and Incubation Center Jaipur- 302017
          </p>
        </div>
      </div>
    );
  }

function Career() {
  return (
    <div>
      <Career1/>
      <Career2/>
      <Footer/>
    </div>
  )
}

export default Career






