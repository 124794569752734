import axios from "axios";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from "./assests/images/logo.svg";
import img_1 from "./assests/images/img_1.png";
import cloud from  "./assests/images/cloud.svg";
import about from "./assests/images/about-img.png";
import img_target from "./assests/images/img_target.svg";
import angell from "./assests/images/angell.png";
import facebook from "./assests/images/facebookk.png";
import insta from "./assests/images/insta.png";
import linkedin from "./assests/images/linkedin.png";
import location from "./assests/images/location.png";
import tweeter from "./assests/images/tweet.png";
import call from "./assests/images/call.png";
import mail from "./assests/images/emacon.png";




function About() {
    return (
      <div>
        <div className="about-container">
        <header>
          <div className="logo">
            <img src={logo} alt="Blog Logo" />
          </div>
          <div className="img_1">
            <img src={img_1} alt="....." />
          </div>
  
          <ul className="navbar">
          <li><Link to="/">Home</Link></li>
      <li><Link to="/career">Career</Link></li>
      <li className="active-link"><Link to="/about" >About </Link></li>
      <li><Link to="/contact">Contact</Link></li>
          </ul>
        </header>
  
        <div className="one-container">
        <h4 className="about-us">About Us</h4>
          <div className="con1">
            <h2>"Who we are"</h2>
          </div>
          <div className="tainer1">
            Eveo is a groundbreaking AI-AR platform desdicated to transforming the
            way individuals curate their personas for events and functions. Our
            user-friendly virtual assistant and real-time simulations redefine the
            fashion and personal styling experience, offering tailored.
          </div>
        </div>
        <div className="cloud-container">
          <img src={cloud} alt="two" />
        </div>
      </div>
      </div>
    );
  }

  function About1() {
    return (
      <div>
        <div className="description-container">
          <div className="img-des2">
            <img src={about} alt="des2" />
          </div>
          <div className="desc3">
            <h1 className="mission">Our Mission</h1>
            <p className="miss-desc1">
              We're on a mission to empower individuals in expressing their
              uniqueness through AI-driven fashion suggestions. Seamlessly fusing
              cutting-edge AI with fashion,our aim is to democratize and make
              fashion sustainable and enjoyable.
            </p>
            <p className="miss-desc2">
              Our ultimate goal is to revolutionize fashion guidance for the
              digital age, redefining how people approach personal style choices.
            </p>
          </div>
          <h1 className="vision">Our Vision</h1>
          <p className="vis-desc">
            Eveo seamlessly blends cutting-edge AI into fashion, revolutionizing
            personal style perception and engagement. Our innovation empowers
            users to embrace sustainable, unique fashion preferences confidently,
            as we aspire to reshape the industry positioning Eveo as the premier
            AI advisor for empowered self-expression.
          </p>
          <div className="img-des1">
            <img src={img_target} alt="des1" />
          </div>
        </div>
      </div>
    );
  }

  function About2() {
    const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const validateEmail = (email) => {
    const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
    return regex.test(email);
  };

  const displayRazorpay = async () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("You are offline...... Failed to load razorpay SDK");
      return;
    }

    const options = {
      key: "rzp_test_BqwXmtR5v1PWNh",
      currency: "INR",
      amount: 3900,
      name: "eveo",
      description: "Thanks for joining",
      handler: async function (response) {
        const jsonData = {
          Email: email,
        };

        try {
          await axios.post("/", jsonData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          alert("Payment Successfully");
          const storedEmail = localStorage.getItem("userEmail");
          localStorage.setItem("userEmail", email);
          console.log("Stored Email:", storedEmail);
        } catch (error) {
          console.error("Error:", error);
        }
      },
      prefill: {
        name: "eveo",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return (
    <div>
      <div className="about2-container">
        <div className="choose">Why Choose Us</div>
        <div className="per-container">
          <h1 className="person">0</h1>
          <h1 className="son">1</h1>
          <p className="personalization">
            <span style={{ color: "blue", fontSize: "28px" }}>
              Personalization:
            </span>
            &nbsp; Our AI tailors its recommendations to your unique style,
            preferences, and needs.
          </p>
        </div>
        <div className="con-container">
          <h1 className="person1">0</h1>
          <h1 className="son1">2</h1>
          <p className="Convenience">
            <span style={{ color: "blue", fontSize: "28px" }}>Convenience:</span>
           &nbsp;  Get fashion advice and outfit ideas anytime, anywhere, on your
            smartphone or computer.
          </p>
        </div>
        <div className="pers-container">
          <h1 className="person1">0</h1>
          <h1 className="son1">3</h1>
          <p className="Convenience">
            <span style={{ color: "blue", fontSize: "28px" }}>Fashion Expertise:</span>
           &nbsp;  Benefit from the collective knowledge of fashion experts and the latest industry trends.
          </p>
        </div>
        <div className="con-container">
          <h1 className="person1">0</h1>
          <h1 className="son1">4</h1>
          <p className="Convenience">
            <span style={{ color: "blue", fontSize: "28px" }}>Time - Saving:</span>
           &nbsp; Avoid endless hours of browsing and shopping, as our AI streamlines the process.
          </p>
        </div>
        <div className="pers-container">
          <h1 className="person1">0</h1>
          <h1 className="son1">5</h1>
          <p className="Convenience">
            <span style={{ color: "blue", fontSize: "28px" }}>Confidence Boost:</span>
           &nbsp;  Look and feel your best with fashion choices that suit you and your lifestyle.
          </p>
        </div>

        <div className="ready-container">
          <p className="ready">
            &quot; Ready to Get Started Join Eveo Today &quot;
          </p>

<form action="/" method="get">
              <div className="input-group">
                
                <input
                  className="ready-input"
                  placeholder='     Enter Your email'
                  type="email2"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setIsValidEmail(true);
                  }}
                />
                
                <button
                  type="button"
                  className="btn-class"
                  onClick={() => displayRazorpay()}
                >
                  Join Now
                </button>
              </div>
              {!isValidEmail && (
                  <p className="invalid" style={{ color: "black" }}>Invalid email address</p>
                )}
             
            </form>
        </div>

        <div className="back-container"></div>
      </div>
    </div>
  );
}

function Footer() {
    return (
      <div>
        <div className="footer-container">
          <div className="footer-in-container">
            <div className="logo-2">
              <img src={logo} alt="Blog Logo" />
            </div>
            <p className="foot-content">
              At Eveo,we are trying to align the fashion according to your
              preference and under your budget by offering personalized
              guidence.using AI-AR to transform personality management for events.
            </p>
  
            <div className="contactus">Contact Us</div>
            <p className="footer-mail">
              {" "}
              <img src={mail} alt="mail" />
              <p className="cmail">info@eveo.in</p>
            </p>
            <p className="footer-call">
              {" "}
              <img src={call} alt="call" />
              <p className="mobile">+0261 3177919</p>
            </p>
            <div className="useful-links">Useful Links</div>
            <p className="us-1">About us</p>
            <p className="us-2">Services</p>
            <p className="us-3">Career</p>
            <p className="us-4">Terms & Conditions</p>
            <div className="footer-con">Let's Stay Connected</div>
            
            <p className="all-5">
              {" "}
              <a href="https://www.instagram.com/eveoai/">
                <img src={insta} alt="insta" />
              </a>
              <a href="https://www.facebook.com/eveoai">
                <img src={facebook} alt="facebook" />
              </a>
              <a href="/">
                <img src={tweeter} alt="tweeter" />
              </a>
              <a href="https://www.linkedin.com/company/eveoai/">
                <img src={linkedin} alt="linkedin" />
              </a>
              <a href="https://wellfound.com/company/eveo">
                <img src={angell} alt="angell" />
              </a>
            </p>
          </div>
  
          <p className="surat-location">
            {" "}
            <img src={location} alt="location" />
          </p>
  
          <p className="surat-add">&nbsp; Surat(HQ)</p>
          <p className="loc">
            G 103-104, Silver Business Point, VIP Circle, Uttran, Surat 394105
          </p>
  
          <p className="ahmbd-location">
            {" "}
            <img src={location} alt="location" />
          </p>
          <p className="ahmbd-add">&nbsp; Ahemdabad</p>
          <p className="loc-1">
            Block no.3,LD Engg. college, Navrangpura, Ahemdabad-380009
          </p>
  
          <p className="patna-location">
            {" "}
            <img src={location} alt="location" />
          </p>
          <p className="patna-add">&nbsp; Patna</p>
          <p className="loc-2">
            Room No. H,IC-IIT Patna Campus,Bihta,Patna-801103
          </p>
  
          <p className="jaypur-location">
            {" "}
            <img src={location} alt="location" />
          </p>
          <p className="jaypur-add">&nbsp; Jaipur</p>
          <p className="loc-3">
            Office no 15, MNIT Innovation and Incubation Center Jaipur- 302017
          </p>
        </div>
      </div>
    );
  }

function AboutUs() {

  return (
    <div>
      <About/>
      <About1/>
      <About2/>
      <Footer/>
    </div>
  )
}

export default AboutUs
