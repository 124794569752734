import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import back_contact from "./assests/images/back_contact.png";
import logo from "./assests/images/logo.svg";
import img_1 from "./assests/images/img_1.png";
import contact_image from "./assests/images/contact_imagee.png";
import map from "./assests/images/map_img.png";
import calll from "./assests/images/call_contact.png";
import mailll from "./assests/images/emacon.png";
import locationn from "./assests/images/location.png";
import angell from "./assests/images/angell.png";
import facebook from "./assests/images/facebookk.png";
import insta from "./assests/images/insta.png";
import linkedin from "./assests/images/linkedin.png";
import location from "./assests/images/location.png";
import tweeter from "./assests/images/tweet.png";
import call from "./assests/images/call.png";
import mail from "./assests/images/emacon.png";

function Contactt() {
  return (
    <div>
      <div className="contact-container">
        <header>
          <div className="logo">
            <img src={logo} alt="Blog Logo" />
          </div>
          <div className="img_1">
            <img src={img_1} alt="....." />
          </div>

          <ul className="navbar">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/career">Career</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li className="active-linkk">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </header>

        <div className="one-container">
          <h4 className="about-us">Contact Us</h4>
          <div className="con1">
            <h2>let’s connect today!</h2>
          </div>
          <div className="tainer1">
            Our AI-enabled fashion stylist platform is your ultimate destination
            for personalized style advice and trend-setting recommendations. By
            connecting with us, you'll unlock a world of possibilities.
          </div>
        </div>
        <div className="contactt-container">
          <img src={contact_image} alt="two" />
        </div>
      </div>
    </div>
  );
}

function Contactt1() {
  return (
    <div>
      <div className="contactt1-container">
        <div className="img-des2">
          <img src={map} alt="des2" />
        </div>
        <div className="desc4">
          <h4 className="contactt-us">Contact Us</h4>
          <h1 className="get">Get in touch with us!</h1>
          <p className="get-desc">
            Have questions or feedback? We're just a click away. Reach out to us
            and let's chat about all things style.
          </p>
          <div className="call-iconn">
            <img src={calll} alt="call" />
            <h4 className="call-1">Have any question?</h4>
            <h4 className="call-2">Free +02613177919</h4>
          </div>
          <div className="maill-iconn">
            <img src={mailll} alt="call" />
            <h4 className="mail-1">Write email</h4>
            <h4 className="mail-2">info@eveo.in</h4>
          </div>
          <div className="locationn-iconn">
            <img src={locationn} alt="call" />
            <h4 className="loca-1">Visit anytime</h4>
            <h4 className="loca-2">
              G 103-104,Silver Business Point, VIP Circle, Utran, Surat
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

function Contactt2() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const validateEmail = (email) => {
    const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
    return regex.test(email);
  };

  const displayRazorpay = async () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("You are offline...... Failed to load razorpay SDK");
      return;
    }

    const options = {
      key: "rzp_test_BqwXmtR5v1PWNh",
      currency: "INR",
      amount: 3900,
      name: "eveo",
      description: "Thanks for joining",
      handler: async function (response) {
        const jsonData = {
          Email: email,
        };

        try {
          await axios.post("/", jsonData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          alert("Payment Successfully");
          const storedEmail = localStorage.getItem("userEmail");
          localStorage.setItem("userEmail", email);
          console.log("Stored Email:", storedEmail);
        } catch (error) {
          console.error("Error:", error);
        }
      },
      prefill: {
        name: "eveo",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return (
    <div>
      <div className="contact-details-container">
        <h4 className="contact-uss">Contact Us</h4>
        <h1 className="gett">Request A Call Back!</h1>
        <input className="first-name" type="text" placeholder="First Name" />
        <input className="last-name" type="text" placeholder="Last Name" />
        <input
          className="phone-number"
          type="tel"
          id="mobileNumber"
          name="mobileNumber"
          placeholder="Phone Number"
          pattern="[0-9]{10}"
          title="Please enter a valid 10-digit mobile number"
        ></input>
        <input
          className="email-address"
          type="emaill"
          placeholder="Email Address"
        />
        <input className="message" type="textt" placeholder="Message..." />
        <button className="submit">Submit</button>
        <div className="back-contact-container">
          <img src={back_contact} alt="0000" />
        </div>

        <div className="ready-containerrr">
          <p className="ready">
            &quot; Ready to Get Started Join Eveo Today &quot;
          </p>

          <form action="/" method="get">
            <div className="input-group">
              <input
                className="ready-input"
                placeholder="     Enter Your email"
                type="email2"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsValidEmail(true);
                }}
              />

              <button
                type="button"
                className="btn-class"
                onClick={() => displayRazorpay()}
              >
                Join Now
              </button>
            </div>
            {!isValidEmail && (
              <p className="invalid" style={{ color: "black" }}>
                Invalid email address
              </p>
            )}
          </form>
        </div>
        <div className="back-container"></div>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div>
      <div className="footer-container">
        <div className="footer-in-container">
          <div className="logo-2">
            <img src={logo} alt="Blog Logo" />
          </div>
          <p className="foot-content">
            At Eveo,we are trying to align the fashion according to your
            preference and under your budget by offering personalized
            guidence.using AI-AR to transform personality management for events.
          </p>

          <div className="contactus">Contact Us</div>
          <p className="footer-mail">
            {" "}
            <img src={mail} alt="mail" />
            <p className="cmail">info@eveo.in</p>
          </p>
          <p className="footer-call">
            {" "}
            <img src={call} alt="call" />
            <p className="mobile">+0261 3177919</p>
          </p>
          <div className="useful-links">Useful Links</div>
          <p className="us-1">About us</p>
          <p className="us-2">Services</p>
          <p className="us-3">Career</p>
          <p className="us-4">Terms & Conditions</p>
          <div className="footer-con">Let's Stay Connected</div>

          <p className="all-5">
            {" "}
            <a href="https://www.instagram.com/eveoai/">
              <img src={insta} alt="insta" />
            </a>
            <a href="https://www.facebook.com/eveoai">
              <img src={facebook} alt="facebook" />
            </a>
            <a href="/">
              <img src={tweeter} alt="tweeter" />
            </a>
            <a href="https://www.linkedin.com/company/eveoai/">
              <img src={linkedin} alt="linkedin" />
            </a>
            <a href="https://wellfound.com/company/eveo">
              <img src={angell} alt="angell" />
            </a>
          </p>
        </div>

        <p className="surat-location">
          {" "}
          <img src={location} alt="location" />
        </p>

        <p className="surat-add">&nbsp; Surat(HQ)</p>
        <p className="loc">
          G 103-104, Silver Business Point, VIP Circle, Uttran, Surat 394105
        </p>

        <p className="ahmbd-location">
          {" "}
          <img src={location} alt="location" />
        </p>
        <p className="ahmbd-add">&nbsp; Ahemdabad</p>
        <p className="loc-1">
          Block no.3,LD Engg. college, Navrangpura, Ahemdabad-380009
        </p>

        <p className="patna-location">
          {" "}
          <img src={location} alt="location" />
        </p>
        <p className="patna-add">&nbsp; Patna</p>
        <p className="loc-2">
          Room No. H,IC-IIT Patna Campus,Bihta,Patna-801103
        </p>

        <p className="jaypur-location">
          {" "}
          <img src={location} alt="location" />
        </p>
        <p className="jaypur-add">&nbsp; Jaipur</p>
        <p className="loc-3">
          Office no 15, MNIT Innovation and Incubation Center Jaipur- 302017
        </p>
      </div>
    </div>
  );
}

function Contact() {
  return (
    <div>
      <Contactt />
      <Contactt1 />
      <Contactt2 />
      <Footer />
    </div>
  );
}

export default Contact;
