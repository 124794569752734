// import axios from "axios";
// import React, { useState } from "react";
// import { Link } from 'react-router-dom'
// import img_1 from "./assests/images/img_1.png";
// import two_person from "./assests/images/two_person.svg";
// import effect from "./assests/images/effect.png";
// import gtu from "./assests/images/gtu.png";
// import icpatna from "./assests/images/icpatna.png";
// import iim from "./assests/images/iim.png";
// import metalogo from "./assests/images/metalogo.png";
// import microsoftstartup from "./assests/images/microsoftstartup.png";
// import nvi from "./assests/images/nvi.png";
// import ministry from "./assests/images/ministry.png";
// import openai from "./assests/images/openai.png";
// import zohostartup from "./assests/images/zohostartup.png";
// import deepkamal from "../Components/assests/images/deepkamal.jpg";
// import daina from "../Components/assests/images/dainaAI.jpg";
// import raj from "../Components/assests/images/raj1.jpg";
// import rajpaul from "../Components/assests/images/raj2.jpg";
// import angell from "./assests/images/angell.png";
// import facebook from "./assests/images/facebookk.png";
// import insta from "./assests/images/insta.png";
// import linkedin from "./assests/images/linkedin.png";
// import location from "./assests/images/location.png";
// import tweeter from "./assests/images/tweet.png";
// import call from "./assests/images/call.png";
// import mail from "./assests/images/emacon.png";
// import logo from "./assests/images/logo.svg";

// function Navbar() {
//   return (
//     <div>
//       <header>
//         <div className="logo">
//           <img src={logo} alt="Blog Logo" />
//         </div>
//         <div className="img_1">
//           <img src={img_1} alt="....." />
//         </div>
      
//         <ul className="navbar">
//         <li className="active-link"><Link to="/" >Home</Link></li>
//         <li><Link to="/career">Career</Link></li>
//       <li><Link to="/about">About </Link></li>
//       <li><Link to="/contact">Contact</Link></li>
          
//         </ul>
       
//         <div className="effect">
//           <img src={effect} alt=".123" />
//         </div>
//       </header>

//       <div className="container">
//         <div className="con">
//           <h2>"Step into the Future of AI Fashion Stylist with Eveo"</h2>
//         </div>
//         <div className="tainer">
//           Eveo empowers users to find their perfect style, embrace sustainable
//           choices, and enhance confidence, revolutionizing fashion advice in the
//           digital era.
//         </div>
//         <div className="main">
//           <button type="button" className="button">
//             Let's Talk
//           </button>
//         </div>
//       </div>

//       <div className="bimg-container">
//         <aside className="girl">
//           <img src={two_person} alt="two" />
//         </aside>
//       </div>
//     </div>
//   );
// }

// function Services() {
//   const imageURLs = [
//     "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694000669614x505316982354909760%2FOutfit%2520Coordination.png?w=128&h=117&auto=compress&dpr=1.25&fit=max",
//     "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694001179444x486920905286103400%2FEvent%2520Styling.png?w=128&h=120&auto=compress&dpr=1.25&fit=max",
//     "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694001410976x941696328850271400%2FPersonalized%2520Recommendation.png?w=128&h=126&auto=compress&dpr=1.25&fit=max",
//     "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002260321x422168099443178430%2FReal%2520Time%2520Simulation.png?w=128&h=125&auto=compress&dpr=1.25&fit=max",
//     "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002309908x349878915740962100%2FVirtual%2520Personal%2520Advice.png?w=96&h=109&auto=compress&dpr=1.25&fit=max",
//     "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002348176x101531332217567060%2FFashion%2520Advice.png?w=96&h=111&auto=compress&dpr=1.25&fit=max",
//     "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002260321x422168099443178430%2FReal%2520Time%2520Simulation.png?w=128&h=125&auto=compress&dpr=1.25&fit=max",
//     "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002309908x349878915740962100%2FVirtual%2520Personal%2520Advice.png?w=96&h=109&auto=compress&dpr=1.25&fit=max",
//     "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002348176x101531332217567060%2FFashion%2520Advice.png?w=96&h=111&auto=compress&dpr=1.25&fit=max",
//   ];

//   const serviceData = [
//     {
//       title: "Personal AI Advisor",
//       description:
//         "Our platform categorizes users based on their age, profession, region & fashion preferences, ensuring personalized guidance and recommendations.",
//     },
//     {
//       title: "Second Opinion",
//       description:
//         "Our second opinion with AI-powered Image and Video Consultation service in which AI get analyze your photos and videos to provide Suggestions.",
//     },
//     {
//       title: "Outfit Coordination",
//       description:
//         "Our platform offers fashion advice on colors and trend recommendations based on client preferences and suitability for various occasions.",
//     },
//     {
//       title: "Generative AI Outfits",
//       description:
//         "Our generative AI-enabled outfits just designed for you. helps you as personal fashion designer in your pocket.creating outfits that suit your taste and the occasions.",
//     },
//     {
//       title: "Event Styling Planner",
//       description:
//         "We offer personalized assistance for special events by curating appropriate outfits and accessories.",
//     },
//     {
//       title: "Communication Skills",
//       description:
//         "improve your effectiveness in conversations, and leave a memorable impact in your personal and professional interactions .&quot;",
//     },
//     {
//       title: "Body Language",
//       description:
//         "Enhance your posture, gestures, and presence, leaving a lasting impression in every social and professional encounter .&quot;",
//     },
//     {
//       title: "Personal Branding",
//       description:
//         "Define and enhance your personal brand with expert guidence for lasting impressions in both personal and professional spheres.",
//     },
//     {
//       title: "Offline Shopping Help",
//       description:
//         "Enhance your in-store shopping with AI guidence. Get real-time fashion recommendations for a better shopping experience.",
//     },
//   ];
//   return (
//     <div>
//       <div className="best-service">
//         <h4 className="best-services">Best Services</h4>
//         <h1 className="top">Top categorised services for you..</h1>
//         <h4 className="desc">
//           With cutting-edge technology, our service provides you with the tools
//           and insights to look and feel your best, every day.
//         </h4>

//         <div className="services-container">
//           <div className="services-list">
//             <div className="service-section">
//               {imageURLs.slice(0, 3).map((url, index) => (
//                 <div key={index} className="service-item">
//                   <div className="img-container">
//                     <img src={url} alt={`img ${index}`} />
//                   </div>
//                   <h4 className="s2">{serviceData[index].title}</h4>
//                   <p className="blog-text1">{serviceData[index].description}</p>
//                 </div>
//               ))}
//             </div>

//             <div className="service-section">
//               {imageURLs.slice(3, 6).map((url, index) => (
//                 <div key={index} className="service-item">
//                   <div className="img-container">
//                     <img src={url} alt={`img ${index}`} />
//                   </div>
//                   <h4 className="s2">{serviceData[index + 3].title}</h4>
//                   <p className="blog-text1">
//                     {serviceData[index + 3].description}
//                   </p>
//                 </div>
//               ))}
//             </div>

//             <div className="service-section">
//               {imageURLs.slice(6, 9).map((url, index) => (
//                 <div key={index} className="service-item">
//                   <div className="img-container">
//                     <img src={url} alt={`img ${index}`} />
//                   </div>
//                   <h4 className="s2">{serviceData[index + 6].title}</h4>
//                   <p className="blog-text1">
//                     {serviceData[index + 6].description}
//                   </p>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="background-container"></div>
//     </div>
//   );
// }

// function Supporter() {
//   const [email, setEmail] = useState("");
//   const [isValidEmail, setIsValidEmail] = useState(true);

//   const loadScript = (src) => {
//     return new Promise((resolve) => {
//       const script = document.createElement("script");
//       script.src = src;
//       script.onload = () => {
//         resolve(true);
//       };
//       script.onerror = () => {
//         resolve(false);
//       };
//       document.body.appendChild(script);
//     });
//   };

//   const validateEmail = (email) => {
//     const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
//     return regex.test(email);
//   };

//   const displayRazorpay = async () => {
//     if (!validateEmail(email)) {
//       setIsValidEmail(false);
//       return;
//     }

//     const res = await loadScript(
//       "https://checkout.razorpay.com/v1/checkout.js"
//     );
//     if (!res) {
//       alert("You are offline...... Failed to load razorpay SDK");
//       return;
//     }

//     const options = {
//       key: "rzp_test_BqwXmtR5v1PWNh",
//       currency: "INR",
//       amount: 3900,
//       name: "eveo",
//       description: "Thanks for joining",
//       handler: async function (response) {
//         const jsonData = {
//           Email: email,
//         };

//         try {
//           await axios.post("/", jsonData, {
//             headers: {
//               "Content-Type": "application/json",
//             },
//           });

//           alert("Payment Successfully");
//           const storedEmail = localStorage.getItem("userEmail");
//           localStorage.setItem("userEmail", email);
//           console.log("Stored Email:", storedEmail);
//         } catch (error) {
//           console.error("Error:", error);
//         }
//       },
//       prefill: {
//         name: "eveo",
//       },
//     };

//     const paymentObject = new window.Razorpay(options);
//     paymentObject.open();
//   };

//   return (
//     <div>
//       <div className="support-container">
//         <div className="desc-container">
//           <h4 className="best-services2">Nurturing Innovation</h4>
//           <h1 className="top2">Where Supporters Foster Our Brand's Growth.</h1>
//           <h4 className="desc2">
//             "We're honored to have the support of industry leaders and
//             well-known brands who believe in our mission."
//           </h4>
//         </div>

//         <div className="circle-container">
//           <div className="blue-border-box animation">
//             <div className="blue-border-box-2 animation">
//               <div className="icpatna-container animation">
//                 <img src={icpatna} alt="icpatna" className="icpatna" />
//               </div>
//               <div className="gtu-container animation">
//                 <img src={gtu} alt="gtu" className="gtu" />
//               </div>
//               <div className="zohostartup-container animation">
//                 <img
//                   src={zohostartup}
//                   alt="zohostartup"
//                   className="zohostartup"
//                 />
//               </div>
//               <div className="blue-border-box-3 animation">
//                 <div className="blue-border-box-4 animation">
//                   <div className="iim-container animation">
//                     <img src={iim} alt="iim" className="iim" />
//                   </div>
//                   <div className="metalogo-container animation">
//                     <img src={metalogo} alt="metalogo" className="metalogo" />
//                   </div>
//                   <div className="microsoftstartup-container animation">
//                     <img
//                       src={microsoftstartup}
//                       alt="microsoftstartup"
//                       className="microsoftstartup"
//                     />
//                   </div>
//                   <div className="nvi-container animation">
//                     <img src={nvi} alt="nvi" className="nvi" />
//                   </div>
//                   <div className="blue-border-box-5 animation">
//                     <div className="openai-container animation">
//                       <img src={openai} alt="openai" className="openai" />
//                     </div>
//                     <div className="ministry-container animation">
//                       <img src={ministry} alt="ministry" className="ministry" />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="ready-container">
//           <p className="ready">
//             &quot; Ready to Get Started Join Eveo Today &quot;
//           </p>

//           <form action="/" method="get">
//             <div className="input-group">
//               <input
//                 className="ready-input"
//                 placeholder="     Enter Your email"
//                 type="email2"
//                 value={email}
//                 onChange={(e) => {
//                   setEmail(e.target.value);
//                   setIsValidEmail(true);
//                 }}
//               />

//               <button
//                 type="button"
//                 className="btn-class"
//                 onClick={() => displayRazorpay()}
//               >
//                 Join Now
//               </button>
//             </div>
//             {!isValidEmail && (
//               <p className="invalid" style={{ color: "black" }}>
//                 Invalid email address
//               </p>
//             )}
//           </form>
//         </div>

//         <div className="back-container"></div>
//       </div>
//     </div>
//   );
// }

// function TeamSection() {
//   return (
//     <>
//       <div className="guidenc-container">
//         <div className="gui-container">
//           <h1 className="meet">Meet the Guiding Stars</h1>
//           <h4 className="meet2">
//             Our journey is enriched by the wisdom and guidance of renowned
//             individuals who serve as our mentors.
//           </h4>
//         </div>
//         <div className="main-11">
//           <ProfileCard
//             imgSrc={deepkamal}
//             name="Deepkal Vaswani,USA"
//             position="Microsoft Conversational AI Cloud Architecture (8 years exp.)"
//           />
//           <ProfileCard
//             imgSrc={raj}
//             name="Raj Krishna,USA"
//             position="Microsoft AI-ML, DevOps,Data Analytics  (10 years exp.)"
//           />
//           <ProfileCard
//             imgSrc={rajpaul}
//             name="Raj Paul,USA"
//             position="Microsoft Conversational AI,Generative AI (8 years exp.)"
//           />
//           <ProfileCard
//             imgSrc={daina}
//             name="Diana,Ireland"
//             position="AI Expert, 4 Years Experience at Microsoft."
//           />
//         </div>
//       </div>
//     </>
//   );
// }

// function ProfileCard({ imgSrc, name, position }) {
//   return (
//     <>
//       <div className="profile-card">
//         <div className="img">
//           <img src={imgSrc} alt={name} />
//         </div>
//         <div className="caption">
//           <h3>{name}</h3>
//           <p>{position}</p>
//           <div className="social-links">
//             <a href="/">
//               <i className="fab fa-facebook"></i>
//             </a>
//             <a href="/">
//               <i className="fab fa-instagram"></i>
//             </a>
//             <a href="/">
//               <i className="fab fa-twitter"></i>
//             </a>
//             <a href="/">
//               <i className="fab fa-twitter"></i>
//             </a>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// function Footer() {
//   return (
//     <div>
//       <div className="footer-container">
//         <div className="footer-in-container">
//           <div className="logo-2">
//             <img src={logo} alt="Blog Logo" />
//           </div>
//           <p className="foot-content">
//             At Eveo,we are trying to align the fashion according to your
//             preference and under your budget by offering personalized
//             guidence.using AI-AR to transform personality management for events.
//           </p>

//           <div className="contactus">Contact Us</div>
//           <p className="footer-mail">
//             {" "}
//             <img src={mail} alt="mail" />
//             <p className="cmail">info@eveo.in</p>
//           </p>
//           <p className="footer-call">
//             {" "}
//             <img src={call} alt="call" />
//             <p className="mobile">+0261 3177919</p>
//           </p>
//           <div className="useful-links">Useful Links</div>
//           <p className="us-1">About us</p>
//           <p className="us-2">Services</p>
//           <p className="us-3">Career</p>
//           <p className="us-4">Terms & Conditions</p>
//           <div className="footer-con">Let's Stay Connected</div>
        
//           <p className="all-5">
//             {" "}
//             <a href="https://www.instagram.com/eveoai/">
//               <img src={insta} alt="insta" />
//             </a>
//             <a href="https://www.facebook.com/eveoai">
//               <img src={facebook} alt="facebook" />
//             </a>
//             <a href="/">
//               <img src={tweeter} alt="tweeter" />
//             </a>
//             <a href="https://www.linkedin.com/company/eveoai/">
//               <img src={linkedin} alt="linkedin" />
//             </a>
//             <a href="https://wellfound.com/company/eveo">
//               <img src={angell} alt="angell" />
//             </a>
//           </p>
//         </div>

//         <p className="surat-location">
//           {" "}
//           <img src={location} alt="location" />
//         </p>

//         <p className="surat-add">&nbsp; Surat(HQ)</p>
//         <p className="loc">
//           G 103-104, Silver Business Point, VIP Circle, Uttran, Surat 394105
//         </p>

//         <p className="ahmbd-location">
//           {" "}
//           <img src={location} alt="location" />
//         </p>
//         <p className="ahmbd-add">&nbsp; Ahemdabad</p>
//         <p className="loc-1">
//           Block no.3,LD Engg. college, Navrangpura, Ahemdabad-380009
//         </p>

//         <p className="patna-location">
//           {" "}
//           <img src={location} alt="location" />
//         </p>
//         <p className="patna-add">&nbsp; Patna</p>
//         <p className="loc-2">
//           Room No. H,IC-IIT Patna Campus,Bihta,Patna-801103
//         </p>

//         <p className="jaypur-location">
//           {" "}
//           <img src={location} alt="location" />
//         </p>
//         <p className="jaypur-add">&nbsp; Jaipur</p>
//         <p className="loc-3">
//           Office no 15, MNIT Innovation and Incubation Center Jaipur- 302017
//         </p>
//       </div>
//     </div>
//   );
// }

// function Home() {
//   return (
//     <div>
//       <Navbar />
//       <Services />
//       <TeamSection />
//       <Supporter />
//       <Footer />
//     </div>
//   );
// }

// export default Home;



import axios from "axios";
import React, { useState } from "react";
import { Link } from 'react-router-dom'
import img_1 from "./assests/images/img_1.png";
import two_person from "./assests/images/two_person.svg";
import effect from "./assests/images/effect.png";
import gtu from "./assests/images/gtu.png";
import icpatna from "./assests/images/icpatna.png";
import iim from "./assests/images/iim.png";
import metalogo from "./assests/images/metalogo.png";
import microsoftstartup from "./assests/images/microsoftstartup.png";
import nvi from "./assests/images/nvi.png";
import ministry from "./assests/images/ministry.png";
import openai from "./assests/images/openai.png";
import zohostartup from "./assests/images/zohostartup.png";
import deepkamal from "../Components/assests/images/deepkamal.jpg";
import daina from "../Components/assests/images/dainaAI.jpg";
import raj from "../Components/assests/images/raj1.jpg";
import rajpaul from "../Components/assests/images/raj2.jpg";
import angell from "./assests/images/angell.png";
import facebook from "./assests/images/facebookk.png";
import insta from "./assests/images/insta.png";
import linkedin from "./assests/images/linkedin.png";
import location from "./assests/images/location.png";
import tweeter from "./assests/images/tweet.png";
import call from "./assests/images/call.png";
import mail from "./assests/images/emacon.png";
import logo from "./assests/images/logo.svg";

function Navbar() {
  const handleClick = () => {
    const supporterSection = document.getElementById("supporter-section");
    supporterSection.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <header>
        <div className="logo">
          <img src={logo} alt="Blog Logo" />
        </div>
        <div className="img_1">
          <img src={img_1} alt="....." />
        </div>
      
        <ul className="navbar">
        <li className="active-link"><Link to="/" >Home</Link></li>
        <li><Link to="/career">Career</Link></li>
      <li><Link to="/about">About </Link></li>
      <li><Link to="/contact">Contact</Link></li>
          
        </ul>
       
        <div className="effect">
          <img src={effect} alt=".123" />
        </div>
      </header>

      <div className="container">
        <div className="con">
          <h2>"Step into the Future of AI Fashion Stylist with Eveo"</h2>
        </div>
        <div className="tainer">
          Eveo empowers users to find their perfect style, embrace sustainable
          choices, and enhance confidence, revolutionizing fashion advice in the
          digital era.
        </div>
        <div className="main">
          <button type="button" className="button" onClick={handleClick}>
            Let's Talk
          </button>
        </div>
      </div>

      <div className="bimg-container">
        <aside className="girl">
          <img src={two_person} alt="two" />
        </aside>
      </div>
    </div>
  );
}

function Services() {
  const imageURLs = [
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694000669614x505316982354909760%2FOutfit%2520Coordination.png?w=128&h=117&auto=compress&dpr=1.25&fit=max",
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694001179444x486920905286103400%2FEvent%2520Styling.png?w=128&h=120&auto=compress&dpr=1.25&fit=max",
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694001410976x941696328850271400%2FPersonalized%2520Recommendation.png?w=128&h=126&auto=compress&dpr=1.25&fit=max",
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002260321x422168099443178430%2FReal%2520Time%2520Simulation.png?w=128&h=125&auto=compress&dpr=1.25&fit=max",
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002309908x349878915740962100%2FVirtual%2520Personal%2520Advice.png?w=96&h=109&auto=compress&dpr=1.25&fit=max",
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002348176x101531332217567060%2FFashion%2520Advice.png?w=96&h=111&auto=compress&dpr=1.25&fit=max",
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002260321x422168099443178430%2FReal%2520Time%2520Simulation.png?w=128&h=125&auto=compress&dpr=1.25&fit=max",
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002309908x349878915740962100%2FVirtual%2520Personal%2520Advice.png?w=96&h=109&auto=compress&dpr=1.25&fit=max",
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fe68bd600813600a9b62dceea2a4c0228.cdn.bubble.io%2Ff1694002348176x101531332217567060%2FFashion%2520Advice.png?w=96&h=111&auto=compress&dpr=1.25&fit=max",
  ];

  const serviceData = [
    {
      title: "Personal AI Advisor",
      description:
        "Our platform categorizes users based on their age, profession, region & fashion preferences, ensuring personalized guidance and recommendations.",
    },
    {
      title: "Second Opinion",
      description:
        "Our second opinion with AI-powered Image and Video Consultation service in which AI get analyze your photos and videos to provide Suggestions.",
    },
    {
      title: "Outfit Coordination",
      description:
        "Our platform offers fashion advice on colors and trend recommendations based on client preferences and suitability for various occasions.",
    },
    {
      title: "Generative AI Outfits",
      description:
        "Our generative AI-enabled outfits just designed for you. helps you as personal fashion designer in your pocket.creating outfits that suit your taste and the occasions.",
    },
    {
      title: "Event Styling Planner",
      description:
        "We offer personalized assistance for special events by curating appropriate outfits and accessories.",
    },
    {
      title: "Communication Skills",
      description:
        "improve your effectiveness in conversations, and leave a memorable impact in your personal and professional interactions .&quot;",
    },
    {
      title: "Body Language",
      description:
        "Enhance your posture, gestures, and presence, leaving a lasting impression in every social and professional encounter .&quot;",
    },
    {
      title: "Personal Branding",
      description:
        "Define and enhance your personal brand with expert guidence for lasting impressions in both personal and professional spheres.",
    },
    {
      title: "Offline Shopping Help",
      description:
        "Enhance your in-store shopping with AI guidence. Get real-time fashion recommendations for a better shopping experience.",
    },
  ];
  return (
    <div>
      <div className="best-service">
        <h4 className="best-services">Best Services</h4>
        <h1 className="top">Top categorised services for you..</h1>
        <h4 className="desc">
          With cutting-edge technology, our service provides you with the tools
          and insights to look and feel your best, every day.
        </h4>

        <div className="services-container">
          <div className="services-list">
            <div className="service-section">
              {imageURLs.slice(0, 3).map((url, index) => (
                <div key={index} className="service-item">
                  <div className="img-container">
                    <img src={url} alt={`img ${index}`} />
                  </div>
                  <h4 className="s2">{serviceData[index].title}</h4>
                  <p className="blog-text1">{serviceData[index].description}</p>
                </div>
              ))}
            </div>

            <div className="service-section">
              {imageURLs.slice(3, 6).map((url, index) => (
                <div key={index} className="service-item">
                  <div className="img-container">
                    <img src={url} alt={`img ${index}`} />
                  </div>
                  <h4 className="s2">{serviceData[index + 3].title}</h4>
                  <p className="blog-text1">
                    {serviceData[index + 3].description}
                  </p>
                </div>
              ))}
            </div>

            <div className="service-section">
              {imageURLs.slice(6, 9).map((url, index) => (
                <div key={index} className="service-item">
                  <div className="img-container">
                    <img src={url} alt={`img ${index}`} />
                  </div>
                  <h4 className="s2">{serviceData[index + 6].title}</h4>
                  <p className="blog-text1">
                    {serviceData[index + 6].description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="background-container"></div>
    </div>
  );
}

function Supporter() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const validateEmail = (email) => {
    const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
    return regex.test(email);
  };

  const displayRazorpay = async () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("You are offline...... Failed to load razorpay SDK");
      return;
    }

    const options = {
      key: "rzp_live_OQIeBUr1MWxTWP",
      currency: "INR",
      amount: 3900,
      name: "eveo",
      description: "Thanks for joining",
      handler: async function (response) {
        const jsonData = {
          Email: email,
        };

        try {
          await axios.post("https://eveobackend.azurewebsites.net/test", jsonData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          alert("Payment Successfully");
          const storedEmail = localStorage.getItem("userEmail");
          localStorage.setItem("userEmail", email);
          console.log("Stored Email:", storedEmail);
        } catch (error) {
          console.error("Error:", error);
        }
      },
      prefill: {
        name: "eveo",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div>
      <div className="support-container">
        <div className="desc-container">
          <h4 className="best-services2">Nurturing Innovation</h4>
          <h1 className="top2">Where Supporters Foster Our Brand's Growth.</h1>
          <h4 className="desc2">
            "We're honored to have the support of industry leaders and
            well-known brands who believe in our mission."
          </h4>
        </div>

        <div className="circle-container">
          <div className="blue-border-box animation">
            <div className="blue-border-box-2 animation">
              <div className="icpatna-container animation">
                <img src={icpatna} alt="icpatna" className="icpatna" />
              </div>
              <div className="gtu-container animation">
                <img src={gtu} alt="gtu" className="gtu" />
              </div>
              <div className="zohostartup-container animation">
                <img
                  src={zohostartup}
                  alt="zohostartup"
                  className="zohostartup"
                />
              </div>
              <div className="blue-border-box-3 animation">
                <div className="blue-border-box-4 animation">
                  <div className="iim-container animation">
                    <img src={iim} alt="iim" className="iim" />
                  </div>
                  <div className="metalogo-container animation">
                    <img src={metalogo} alt="metalogo" className="metalogo" />
                  </div>
                  <div className="microsoftstartup-container animation">
                    <img
                      src={microsoftstartup}
                      alt="microsoftstartup"
                      className="microsoftstartup"
                    />
                  </div>
                  <div className="nvi-container animation">
                    <img src={nvi} alt="nvi" className="nvi" />
                  </div>
                  <div className="blue-border-box-5 animation">
                    <div className="openai-container animation">
                      <img src={openai} alt="openai" className="openai" />
                    </div>
                    <div className="ministry-container animation">
                      <img src={ministry} alt="ministry" className="ministry" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ready-container" id="supporter-section">
          <p className="ready">
            &quot; Ready to Get Started Join Eveo Today &quot;
          </p>

          <form action="/" method="get">
            <div className="input-group">
              <input
                className="ready-input"
                
                placeholder="     Enter Your email"
                type="email2"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsValidEmail(true);
                }}
              />

              <button
                type="button"
                className="btn-class"
                onClick={() => displayRazorpay()}
              >
                Join Now
              </button>
            </div>
            {!isValidEmail && (
              <p className="invalid" style={{ color: "black" }}>
                Invalid email address
              </p>
            )}
          </form>
        </div>

        <div className="back-container"></div>
      </div>
    </div>
  );
}

function TeamSection() {
  return (
    <>
      <div className="guidenc-container">
        <div className="gui-container">
          <h1 className="meet">Meet the Guiding Stars</h1>
          <h4 className="meet2">
            Our journey is enriched by the wisdom and guidance of renowned
            individuals who serve as our mentors.
          </h4>
        </div>
        <div className="main-11">
          <ProfileCard
            imgSrc={deepkamal}
            name="Deepkal Vaswani,USA"
            position="Microsoft Conversational AI Cloud Architecture (8 years exp.)"
          />
          <ProfileCard
            imgSrc={raj}
            name="Raj Krishna,USA"
            position="Microsoft AI-ML, DevOps,Data Analytics  (10 years exp.)"
          />
          <ProfileCard
            imgSrc={rajpaul}
            name="Raj Paul,USA"
            position="Microsoft Conversational AI,Generative AI (8 years exp.)"
          />
          <ProfileCard
            imgSrc={daina}
            name="Diana,Ireland"
            position="AI Expert, 4 Years Experience at Microsoft."
          />
        </div>
      </div>
    </>
  );
}

function ProfileCard({ imgSrc, name, position }) {
  return (
    <>
      <div className="profile-card">
        <div className="img">
          <img src={imgSrc} alt={name} />
        </div>
        <div className="caption">
          <h3>{name}</h3>
          <p>{position}</p>
          <div className="social-links">
            <a href="/">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="/">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="/">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="/">
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

function Footer() {
  return (
    <div>
      <div className="footer-container">
        <div className="footer-in-container">
          <div className="logo-2">
            <img src={logo} alt="Blog Logo" />
          </div>
          <p className="foot-content">
            At Eveo,we are trying to align the fashion according to your
            preference and under your budget by offering personalized
            guidence.using AI-AR to transform personality management for events.
          </p>

          <div className="contactus">Contact Us</div>
          <p className="footer-mail">
            {" "}
            <img src={mail} alt="mail" />
            <p className="cmail"><a href="mailto:info@eveo.in">info@eveo.in</a></p>
          </p>
          <p className="footer-call">
            {" "}
            <img src={call} alt="call" />
            <p className="mobile">+0261 3177919</p>
          </p>
          <div className="useful-links">Useful Links</div>
          <p className="us-1">About us</p>
          <p className="us-2">Services</p>
          <p className="us-3">Career</p>
          <p className="us-4">Terms & Conditions</p>
          <div className="footer-con">Let's Stay Connected</div>
        
          <p className="all-5">
            {" "}
            <a href="https://www.instagram.com/eveoai/">
              <img src={insta} alt="insta" />
            </a>
            <a href="https://www.facebook.com/eveoai">
              <img src={facebook} alt="facebook" />
            </a>
            <a href="/">
              <img src={tweeter} alt="tweeter" />
            </a>
            <a href="https://www.linkedin.com/company/eveoai/">
              <img src={linkedin} alt="linkedin" />
            </a>
            <a href="https://wellfound.com/company/eveo">
              <img src={angell} alt="angell" />
            </a>
          </p>
        </div>

        <p className="surat-location">
          {" "}
          <img src={location} alt="location" />
        </p>

        <p className="surat-add">&nbsp; Surat(HQ)</p>
        <p className="loc">
          G 103-104, Silver Business Point, VIP Circle, Uttran, Surat 394105
        </p>

        <p className="ahmbd-location">
          {" "}
          <img src={location} alt="location" />
        </p>
        <p className="ahmbd-add">&nbsp; Ahemdabad</p>
        <p className="loc-1">
          Block no.3,LD Engg. college, Navrangpura, Ahemdabad-380009
        </p>

        <p className="patna-location">
          {" "}
          <img src={location} alt="location" />
        </p>
        <p className="patna-add">&nbsp; Patna</p>
        <p className="loc-2">
          Room No. H,IC-IIT Patna Campus,Bihta,Patna-801103
        </p>

        <p className="jaypur-location">
          {" "}
          <img src={location} alt="location" />
        </p>
        <p className="jaypur-add">&nbsp; Jaipur</p>
        <p className="loc-3">
          Office no 15, MNIT Innovation and Incubation Center Jaipur- 302017
        </p>
      </div>
    </div>
  );
}

function Home() {
  return (
    <div>
      <Navbar />
      <Services />
      <TeamSection />
      <Supporter />
      <Footer />
    </div>
  );
}

export default Home;



